<template>
  <div>
    <div class="mb-3">
      <label
        for="exampleFormControlInput1"
        class="form-label"
      >Certificate Name </label>
      <input
        v-model="item.name"
        type="text"
        class="form-control"
      >
    </div>
    <div class="mb-3">
      <label
        for="exampleFormControlInput1"
        class="form-label"
      >Certificate URL </label>
      <input
        v-model="item.url"
        type="text"
        class="form-control"
      >
    </div>
    Upload Private Key
    <a-upload-dragger class="d-block mt-1 mb-3">
      <p class="ant-upload-drag-icon">
        <inbox-outlined />
      </p>
      <p class="ant-upload-text">
        Click or drag file to this area to upload
      </p>
      <p class="ant-upload-hint">
        Support for a single  bulk upload. Strictly prohibit from uploading company data or other
        band files
      </p>
    </a-upload-dragger>
    <div class="d-flex justify-content-end">
      <button class="btn btn-primary">
        Submit
      </button>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      item: {
        name: null,
        url: null,
        file: null,
      },
    }
  },
}
</script>
